@import "../../../../ba_gp_storefront_core/cartridge/scss/default/mixin/breakpoint";
@import "../../../../ba_gp_storefront_core/cartridge/scss/default/variables";
@import "../../../../ba_gp_storefront_core/cartridge/scss/default/blueacorn/var";


/* ============================================================================
Herocarouselitem component
============================================================================ */

$ex: ex-largeimage;

/* ============================================================================
Common styles updates
============================================================================ */


/* ============================================================================
Custom styles
============================================================================ */

.#{$ex} {
    &__headline-wrapper {
        color: $black;

        .#{$ex}__headline {
            text-transform: none;
        }
    }

    &__image-wrapper {
        position: relative;
    }

    &__image {
        height: var(--height);
        width: var(--width);
        border-radius: var(--border-radius);
        box-sizing: content-box;

        @include bp(min-width, $bp-medium) {
            height: var(--height-md);
            width: var(--width-md);
            border-radius: var(--border-radius-m);
        }
    }

    &__snippet {
        p {
            font-family: inherit;
            font-size: inherit;
            line-height: inherit;
        }
    }

    &__button {
        min-width: var(--min-width) !important;

        @include bp(min-width, $bp-medium) {
            min-width: var(--min-width-md) !important;
        }
    }
}

.sti-icon {
    bottom: 15px;
    height: 36px;
    left: 15px;
    position: absolute;
    width: 36px;

    @include bp(min-width, $bp-medium) {
        bottom: 50px;
        height: 48px;
        left: 50px;
        width: 48px;
    }

    img {
        width: 100%;
    }
}
